import "./App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import useWebSocket from "react-use-websocket";
import logo from "../src/logo-white.svg";
import logotwo from "../src/logotwo.png";

const App = () => {
  const [audioLeng, setAudioLeng] = React.useState([]);
  const [selected, setSelected] = useState("");
  const [link, setLink] = React.useState("");
  const [audioName, setAudioName] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const [audioSaw, setAudioSaw] = React.useState([]);
  const [audioSawFinal, setAudioSawFinal] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [loadedFile, setLoadedFile] = React.useState(0);
  const [openButton, setOpenButton] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  useEffect(() => {
    axios
      .get(`https://semalang-api.coscosoft.ru/api/v1/audio`, {})
      .then(function (response) {
        setAudioLeng(response.data.audio);
        setSelected(audioLeng[0]?.name);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    setSelected(event.target.value);
    setAudioName(event.target.value);
    document.querySelectorAll("option").forEach(function (item) {
      if (item.innerText == `${event.target.value}`) {
        setLink(item.id);
      }
    });
    setAudioSaw([]);
    setAudioSawFinal([]);
  };

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `wss://semalang-api.coscosoft.ru/ws`,
    {
      onMessage(event) {
        let data = JSON.parse(event.data);
        if (data?.topic === 'processing.end') {
          setProcessing(false)
        }
        setAudioSaw([...audioSaw, JSON.parse(event.data)]);
        setAudioSawFinal(
          audioSaw.sort(function (a, b) {
            return parseFloat(a.index) - parseFloat(b.index);
          })
        );
      },
    }
  );

  const onSubmit = () => {
    setVisible(true);
    const audioSelected = {
      topic: "start",
      unit: {
        audio_name: `${audioName}`,
      },
    };
    setProcessing(true)
    sendMessage(JSON.stringify(audioSelected));
  };

  const handleSelectedFile = (e) => {
    setSelectedFile(e.target.files[0]);
    setLoadedFile(0);
    setOpenButton(true);
  };

  const handleUpload = () => {
    const data = new FormData();
    data.append("file", selectedFile, selectedFile?.name);
    axios
      .post("https://semalang-api.coscosoft.ru/api/v1/audio", data, {
        onUploadProgress: (ProgressEvent) => {
          setLoadedFile((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((res) => {
        setLoadedFile(0);
        setSelectedFile("");
        setOpenButton(false);
      });
  };

  return (
    <div className="App">
      <div className="wrapper__logo--index">
      <img src={logo} alt="Логотип" />
      <img src={logotwo} alt="Логотип" />
      </div>
      <div className="select__wrapper">
        <div className="select__title">Загрузите аудиозапись</div>
        <div className="input-file__wrapper">
          <label className="input-file">
            <input accept="audio/wav" type="file" name="" id="" onChange={handleSelectedFile} />
            <span>Выберите файл</span>
          </label>
          <button
            className={
              openButton ? "input-file__button" : "input-file__button-none"
            }
            onClick={handleUpload}
          >
            Загрузить
          </button>
          <div> {Math.round(loadedFile, 2)} %</div>
        </div>
      </div>

      <div className="select__wrapper select__wrapper--two">
        <div className="select__title">Выберите аудиозапись</div>

        <select
          value={selected}
          onChange={handleChange}
          className="basic-single select__input"
        >
          <option></option>
          {audioLeng.map(({ name, link }, index) => (
            <option key={index} id={link}>
              {name}
            </option>
          ))}
        </select>

        {link && (
          <audio controls src={`${link}`}>
            <a href={`${link}`}>Download audio</a>
          </audio>
        )}

        <button onClick={onSubmit} className="select__button" type="button">
          Обработать
        </button>
      </div>
      {visible && (
        <>
          <div className="select__title">Обработанные аудиоматериалы</div>
          <div className="processing__wrapper--text">{processing === true ? <span>Идет загрузка<span className="dot dot1">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span></span> : ''}</div>
          <ul className="select__audio-list">
            {audioSawFinal.map(
              ({ duration, language, link, start, end, topic }, index) => (
                <li
                  key={index}
                  className={
                    topic ? "select__audio-item--none" : "select__audio-item"
                  }
                >
                  <div>
                    с {start} по {end}
                  </div>
                  <div>{language}</div>
                  <audio controls src={link}>
                    <a href={link}>Download audio</a>
                  </audio>
                </li>
              )
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default App;
